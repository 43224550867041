<template>
    <img loading="lazy" 
         :src="source" 
         class="hide-until-create" 
         :class="{'fade-in-on-create': loaded || error }" 
         @load="loaded = true" 
         @error="error = true" />
</template>
<script>
export default {
    props: ['src', 'fallbackSrc'],

    computed: {
        source() {
            return !this.src ? this.fallbackSrc : (this.error ? this.fallbackSrc : this.src);
        }
    },

    data() {
        return {
            error: false,
            loaded: false
        }
    }
}
</script>