<template>
    <markdown-output :content="sectionContent || ''">

    </markdown-output>
</template>
<style>

</style>
<script>
export default {
    props: ['show', 'sectionIndex'],

    computed: {
        sectionContent() {
            return this.show.Sections[this.sectionIndex].Content;
        },

        sectionName() {
            return this.show.Sections[this.sectionIndex].Title;
        },
    },


    data() {
        return {
        }
    },

    mounted() {
    }
}
</script>