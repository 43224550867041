<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col text-center">
                <h1>
                    {{sectionName}}
                </h1>
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-center align-items-center">
                <ul class="nav d-flex justify-content-center "> 
                    <li v-for="speaker in attendees"
                        v-bind:key="speaker.Code"
                        class="nav-item m-2 speaker-list nav-link text-center d-flex flex-column align-items-center"
                        :class="{'active' : selectedSpeaker == speaker }"
                        @click="selectSpeaker(speaker)">
                        <img-g :src="speaker.ContactInfo.PhotoUrl"
                                class="border shadow" />
                        <h5 class="mt-2">{{speaker.DisplayName}}</h5>
                        <h6 v-if="speaker.ContactInfo && speaker.ContactInfo.Title">
                            {{speaker.ContactInfo.Title}}
                        </h6>
                    </li>
                </ul>                       
            </div>
        </div>
        <div class="row jump-target" v-if="selectedSpeaker" ref="speakerInfo">
            <div class="col-md">
                <img-g class="speaker float-start border shadow"
                     :src="selectedSpeaker.ContactInfo.PhotoUrl" />
                <h2>{{selectedSpeaker.DisplayName}}</h2>
                <h3 v-if="selectedSpeaker.ContactInfo && selectedSpeaker.ContactInfo.Title">{{selectedSpeaker.ContactInfo.Title}}</h3>
                <markdown-output :content="selectedSpeaker.ContactInfo.Bio">

                </markdown-output>
            </div>
            <div class="col-md-4" v-if="selectedSpeaker.ContactInfo.ContactInfo.length > 0">
                <h3>More Info</h3>
                <nav class="nav nav-pills nav-justified flex-column">
                    <a v-for="link in selectedSpeaker.ContactInfo.ContactInfo"
                        v-bind:key="link.Value"
                        :href="link.Value"
                        target="_blank"
                        class="nav-link fs-5 active m-1">
                        {{link.DisplayText}}
                    </a>
                </nav>
            </div>
        </div>

    </div>
</template>
<script>
import Vue from 'vue';

export default {
    props: ['show', 'sectionIndex'],

    watch: {
        sectionIndex() {
            this.selectedSpeaker = null;
        }
    },

    computed: {
        sectionName() {
            return this.show.Sections[this.sectionIndex].Title;
        },

        attendees() {
            let selectedSection = this.show.Sections[this.sectionIndex];

            let toReturn = [];

            let sectionCategories = selectedSection.Categories;

            sectionCategories.forEach(c => {
                let attendees = this.show.Attendees.filter(a => a.Categories.find(ac => ac.Code == c.Code));

                if(!attendees || attendees.length == 0) {
                    return;
                }

                toReturn.push(...attendees);
            });

            return toReturn;
        }
    },

    methods: {
        selectSpeaker(speaker) {
            this.selectedSpeaker = speaker;
            Vue.nextTick(() => {

                let navbarSmall = document.getElementById("navbarHeaderSmall");
                let navbarLarge = document.getElementById("navbarHeaderLarge");

                let largestHeight = Math.max(navbarSmall.offsetHeight, navbarLarge.offsetHeight);

                let position = this.$refs.speakerInfo.offsetTop;

                window.scrollTo(0, position - largestHeight);
            });
        },
    },

    data() {
        return {
            selectedSpeaker: null
        }
    }
}
</script>