<template>
    <div v-if="show">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="navbarToggleSmall">
            <div class="offcanvas-header">
                <button type="button" 
                        class="btn-close text-reset" 
                        data-bs-dismiss="offcanvas" 
                        aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <ul class="nav nav-pills justify-content-center">
                    <li class="nav-item" data-bs-dismiss="offcanvas" >
                        <router-link :to="`/${showCode}/home`"  
                                     class="nav-link indigo" 
                                     exact-active-class="active">
                            Home
                        </router-link>
                    </li>
                    <template v-for="(section, ix) in show.Sections">
                        <li class="nav-item" data-bs-dismiss="offcanvas" 
                        
                            v-if="section.Size.indexOf('-home') == -1"
                            v-bind:key="section.Code">
                            <router-link :to="`/${showCode}/${getNavRouteName(section)}/${ix}`" class="nav-link" active-class="active"
                                        @click.native="bringRouterIntoView()">
                                {{section.Title}}
                            </router-link>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <nav class="sticky-top d-flex d-md-none bg-white border-bottom p-2" id="navbarHeaderSmall">
            <button class="navbar-toggler btn btn-primary" 
                    type="button" 
                    data-bs-toggle="offcanvas" 
                    data-bs-target="#navbarToggleSmall" 
                    aria-controls="navbarToggleSmall" 
                    aria-label="Toggle navigation">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </button>
        </nav>
        <nav class="sticky-top d-none bg-white border-bottom p-2 justify-content-center d-md-flex" v-if="show.Sections.length > 1" id="navbarHeaderLarge">
            <ul class="nav nav-pills justify-content-center">
                <li class="nav-item">
                    <router-link :to="`/${showCode}/home`" class="nav-link indigo" exact-active-class="active">
                        Home
                    </router-link>
                </li>
                <template v-for="(section, ix) in show.Sections">
                    <li class="nav-item" 
                        v-if="section.Size.indexOf('-home') == -1"
                        v-bind:key="section.Code">
                        <router-link :to="`/${showCode}/${getNavRouteName(section)}/${ix}`" class="nav-link" active-class="active"
                                     @click.native="bringRouterIntoView()">
                            {{section.Title}}
                        </router-link>
                    </li>
                </template>
            </ul>
        </nav>
        <main class="container mt-4 border shadow">
            <div class="row" v-if="show.Sections[0].SectionTypeCode == 'HtmlContent' && $route.name != 'ModConsole'">
                <markdown-output class="col" :content="show.Sections[0].Content">

                </markdown-output>
            </div>

            <router-view class="mt-3" :show="show" ref="routerarea">
            </router-view>
        </main>

        <footer class="m-4 text-center">
            &copy; 2016-2021 <a href="https://www.tractus.ca">Tractus Event Passport</a>.
        </footer>
        <vue-title :title="show.Name" :brand="true"></vue-title>

    </div>
    <div v-else-if="fetchingShow" 
         class="full-height d-flex align-items-center justify-content-center">
        <div class="border p-3 shadow-sm text-center">
            <h3>Loading, please wait...</h3>        
            <vue-title :title="'Loading...'" :brand="true"></vue-title>
        </div>
    </div>
    <div v-else-if="error"
         class="full-height d-flex align-items-center justify-content-center">
        <div class="alert alert-danger">
            <vue-title :title="'Failed to load'" :brand="true"></vue-title>
            We're sorry. We couldn't load that page.
        </div>
    </div>
</template>
<script>
import { getApiUrl } from './environment.hci';

export default {
    props: ['showCode'],

    watch: {
        showCode() {
            this.build();
        }
    },

    methods: {
        onWindowMessage(event){
            if(event.data.Type && event.data.Type == 'Passport'){
                let show = event.data.Attendee.Show;
                this.show.Theme = show.Theme;
                this.show.Sections = show.Sections;
                this.show.Name = show.Name;
                this.rebuildTheme(); 
            }
        },
        async build() {
            try {
                let showResult = await fetch(`${getApiUrl()}/api/publicshow/${this.showCode}`);

                if(showResult.status == 404) {
                    this.error = "We're sorry. We couldn't find that page.";
                    this.fetchingShow = false;
                    return;
                }

                let show = await showResult.json();

                let toSave =  show.Result.Show;
                toSave.Attendees = show.Result.Attendees;

                this.show = toSave;

                this.rebuildTheme();

                this.fetchingShow = false;

            } catch(ex) {

            }
        },

        rebuildTheme() {
            let style = document.getElementById('theme-stylesheet');
            if(style) {
                style.innerHTML = '';
            }


            let newMetaColor = '#203864';

            if(this.show.Theme
                && this.show.Theme.StyleSheet) {
                
                let candidateSheet = this.show.Theme.StyleSheet;
                style.innerHTML = candidateSheet;   
            }

            if(!newMetaColor) {
                newMetaColor = '#203864';
            }

            let metaThemeColor = document.querySelector("meta[name=theme-color]");
            if(metaThemeColor) {
                metaThemeColor.setAttribute('content', newMetaColor);
            }            
        },

        bringRouterIntoView() {
            let routerView = this.$refs.routerarea;
            if(!routerView) {
                return;
            }

            routerView.$el.scrollIntoView();
        },

        getNavRouteName(section) {
            switch(section.SectionTypeCode)
            {
                case 'Attendees':
                    return 'speakers';
                case 'Vendors':
                    return 'pages';
                case 'HtmlContent':
                    return 'content'
                default:
                    return 'pages'    
            }
        },        
    },

    created() {
        this.build();
        window.addEventListener('message',this.onWindowMessage,false);
    },

    data() {
        return {
            show: null,
            fetchingShow: false,
            error: null,
        }
    }
}
</script>