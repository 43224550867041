<template>
    <div class="container-fluid">
        <template v-for="(section, ix) in show.Sections">
            <div v-if="section.Size != 'menuonly' && (ix != 0 || (ix == 0 && section.SectionTypeCode != 'HtmlContent'))"
                 class="row mt-4" 
                 v-bind:key="section.Code">
                <markdown-output v-if="section.SectionTypeCode == 'HtmlContent'" :content="section.Content">
                </markdown-output>
                <page-list v-if="section.SectionTypeCode == 'Vendors'"
                           :sectionIndex="ix"
                           :show="show">
                </page-list>
                <speaker-list v-else-if="section.SectionTypeCode == 'Attendees'"
                              :sectionIndex="ix"
                              :show="show">

                </speaker-list>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props: ['show'],

    watch: {
    },

    computed: {

    },

    data() {
        return {
        }
    },

    created() {

    }
}
</script>