import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home'
import Speakers from '../components/speakers'
import PageList from '../components/pagelist'
import HtmlPage from '../components/html.page';
import ShowPage from '../components/show.page';

import { redirectFromShowCode } from '../components/redirectfromshowcode'

Vue.use(VueRouter)

const routes = [
    {
        path: '/:showCode',
        props: true,
        component: ShowPage,
        name: 'ShowHome',
        children: [
            {
                path: 'speakers/:sectionIndex',
                name: "speakers",
                component: Speakers,
                props: true
            },
            {
                path: 'pages/:sectionIndex',
                name: 'PageList',
                component: PageList,
                props: true
            },
            {
                path: 'content/:sectionIndex',
                name: 'HtmlPage',
                component: HtmlPage,
                props: true
            },
            {
                path: 'home',
                name: 'Home',
                component: Home,
                props: true
            }
        ]
    },
    {
        path: '/enter/:showCode',
        name: "RedirectFromShowCode",
        beforeEnter: redirectFromShowCode,
        props: true
    }
]

const router = new VueRouter({
    routes
})

export default router
