<script>
    export default {
        name: 'vue-title',
        props: ['title', 'brand'],
        watch: {
            title: {
                immediate: true,
                handler() {
                    this.$root.$emit('title-change', this.title);
                    document.title = `${this.title}${this.brand ? " | Tractus Event Passport" : ''}`;
                }
            }
        },
        render () {
        },
    }
</script>