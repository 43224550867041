<template>
    <div ref="output">

    </div>
</template>
<script>
const marked = require('marked')

export default {
    props: ['content'],

    watch: {
        content() {
            this.rebuildOutput();
        }
    },

    methods: {
        rebuildOutput() {
            let container = this.$refs.output;

            container.innerHTML = marked(this.content);            
        }
    },

    mounted() {
        this.rebuildOutput();
    }
}
</script>