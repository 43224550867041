<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col text-center">
                <h1>
                    {{sectionName}}
                </h1>
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-center align-items-center">
                <ul class="nav d-flex justify-content-center align-items-center"> 
                    <li v-for="page in pages"
                        v-bind:key="page.Code"
                        class="nav-item m-2 page-item border shadow nav-link d-flex"
                        :class="{'active' : selectedPage == page }"
                        @click="selectPage(page)">
                        <img-g :src="page.ContactInfo.PhotoUrl" />
                    </li>
                </ul>                       
            </div>
        </div>
        <div class="row" v-if="selectedPage" ref="pageDetails">
            <div class="col-md">
                <h2>{{selectedPage.Name}}</h2>
                <img-g class="pagelogo float-start border shadow-sm p-2"
                     :src="selectedPage.ContactInfo.PhotoUrl" />
                <markdown-output :content="selectedPage.ContactInfo.Bio" class="fs-5">

                </markdown-output>
            </div>
            <div class="col-md-4" v-if="selectedPage.ContactInfo.ContactInfo.length > 0">
                <img-g :src="selectedPage.PrimaryMedia.ThumbnailUri"
                     v-if="selectedPage.PrimaryMedia.ThumbnailUri"
                     class="img-fluid cover-photo border rounded" />
                <nav class="nav nav-pills nav-justified flex-column">
                    <a v-for="link in selectedPage.ContactInfo.ContactInfo"
                        v-bind:key="link.Value"
                        :href="link.Value"
                        target="_blank"
                        class="nav-link fs-5 active m-1">
                        {{link.DisplayText}}
                    </a>
                </nav>
            </div>
        </div>

    </div>
</template>
<style>

</style>
<script>
import Vue from 'vue';

export default {
    props: ['show', 'sectionIndex'],

    watch: {
        sectionIndex() {
            this.selectedPage = null;
        }
    },

    computed: {
        sectionName() {
            return this.show.Sections[this.sectionIndex].Title;
        },

        pages() {
            let selectedSection = this.show.Sections[this.sectionIndex];

            let toReturn = [];

            let sectionCategories = selectedSection.Categories;

            sectionCategories.forEach(c => {
                let pages = this.show.Vendors[c.Code];

                if(!pages || pages.length == 0) {
                    return;
                }

                toReturn.push(...pages);
            });

            return toReturn;
        }
    },

    methods: {
        selectPage(page) {
            this.selectedPage = page;
            Vue.nextTick(() => {

                let navbarSmall = document.getElementById("navbarHeaderSmall");
                let navbarLarge = document.getElementById("navbarHeaderLarge");

                let largestHeight = Math.max(navbarSmall.offsetHeight, navbarLarge.offsetHeight);

                let position = this.$refs.pageDetails.offsetTop;

                window.scrollTo(0, position - largestHeight);
            });
        },
    },

    data() {
        return {
            selectedPage: null
        }
    },

    created() {

    }
}
</script>