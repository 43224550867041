import Vue from 'vue'
import App from './App.vue'
import router from './router'
import PageList from './components/pagelist'
import SpeakerList from './components/speakers'
import ImgGraceful from './components/img.graceful'
import VueTitle from './components/title';
import MarkdownOutput from './components/markdown.view';

import "./styles/common.sass";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

Vue.config.productionTip = false

Vue.component('speaker-list', SpeakerList)
Vue.component('page-list', PageList)
Vue.component('img-g', ImgGraceful);
Vue.component('vue-title', VueTitle)
Vue.component('markdown-output', MarkdownOutput);

let style = document.getElementById('theme-stylesheet');
if(!style) {
    style = document.createElement('style');
    style.setAttribute('id', 'theme-stylesheet');
    document.head.appendChild(style);
}

window.$bus = new Vue();

new Vue({
  router,
  render: h => h(App)
}).$mount('#wrapper')
